.dev {
    height: 340px;
    width: 860px;
}

.hashnode {
    height: 420px;
    width: 800px;
}

.cnblogs{
  height: 493px;
  width: 900px;
}

.juejin{
  height: 512px;
  width: 768px;
}

.wexin{
  width:900px;
  height:383px;
}